//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  components: {},
  data: () => ({}),

  computed: {
    ...mapState(['contact']),
    aboutWords() {
      return this.contact.text.split(' ')
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const { contactTextWords, contactText } = this.$refs

      const gsap = this.$gsap

      const aboutTextWordsTl = gsap.timeline({
        scrollTrigger: {
          trigger: contactText,
          start: 'top 85%',
          end: '50% 70%',
          scrub: true,
          once: true,
        },
      })

      contactTextWords.forEach((word) => {
        aboutTextWordsTl.to(word, {
          onUpdate: () => word.classList.add('contact__text__word--revealed'),
        })
      })
    },
  },
}
